<template>
    <div class="user-info">
            <div class="
								user-img
								text-center
								d-flex
								justify-content-center
								"
                 @click="avatarVisible = true"
            >
                <div class="Uimg_cover"></div>
                <div class="Uimg d-flex justify-content-center align-items-center">
                    <img class="avatar-img" :src="user.avatar" alt=""/>
                    <img class="avatar-box" :src="user.avatar_box" alt="" v-if="user.avatar_box"/>
                </div>
            </div>
            <div class="user-name">
                            <span class="Uname">
                                {{ user.truename }}({{ eyes ? user.username : '✱✱✱✱✱✱' }})
                            </span>
                <i :class="['fa', eyes ? 'fa-eye' : 'fa-eye-slash']" @click="changeEyes"></i>
            </div>
            <div class="user-level">
                <img :src="user.level_img" alt="我的段位" @click="getUserInfo" title="点击刷新"/>
                <span class="l-username">
                                {{ user.level_name }}
                            </span>
                <span class="user-stars" v-if="user.start >= 6">✯x{{ user.start }}</span>
                <span class="user-stars" v-else v-for="index of user.start" :key="index">✯</span>
            </div>
            <div class="user-progress">
                <el-progress
                        :percentage="jinDu"
                        :stroke-width="13"
                        :show-text="textShow"
                ></el-progress>
                <div class="u-info">
                                <span class="u-info-num">
                                    {{
                                        user.baoshi || 0
                                    }}
                                </span>/
                    <span class="u-info-total" v-if="user.level">
                                    {{ user.level.end_inter }}
                                </span>
                </div>
            </div>
        </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "UserAvatar",
    data() {
        return {
            textShow: false,
            avatarVisible: false,
            eyes: false,
            score: 60,
            scoreWidth: 10,
        }
    },
    created() {
        this.getUserInfo()
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        jinDu: function () {
            // `this` 指向 vm 实例
            var jd = 0;
            if (this.user.level != undefined) {
                var baoshi = this.user.baoshi || 0;
                var upper_exp = this.user.level.end_inter;
                var lower_exp = this.user.level.start_inter;
                var all = upper_exp - lower_exp;
                var j = baoshi - lower_exp;
                //console.log("upper_exp：" + upper_exp + "lower_exp：" + lower_exp);
                if (all <= 0) {
                    jd = 0;
                } else if (j > all) {
                    jd = 100;
                } else {
                    jd = Math.round((j / all) * 100);
                }
            }
            /*if(upper_exp>0){
	                    jd = Math.round((lower_exp / upper_exp) * 100);
	                  }
	                  if(jd<0){
	                    jd = 0;
	                  }else if(jd>100){
	                    jd = 100;
	                  }*/
            return jd;
        },
    },
    methods: {
        getUserInfo() {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
            };
            //console.log(params);
            this.axios
                    .post("/api/user/get_user_info", params)
                    .then((res) => {
                        let data = res.data;
                        //console.log("get_user_info");
                        //console.log(data, "用户信息");
                        if (data.status_code == 200) {
                            //console.log(data.data);
                            this.$store.commit("setUser", data.data);
                        } else if (res.data.status_code == 202) {
                        } else {
                            //this.$message(res.data.msg)
                        }
                    })
                    .catch((err) => {
                        //this.loading = false
                    });
        },
        changeEyes() {
            this.eyes = !this.eyes;
        },
    }
}
</script>

<style lang="scss" scoped>
$glColor: #65abf3;
.user-name {
    margin-top: .26rem;
    i {
        margin-left: 8px;
        font-size: 20px;
        vertical-align: -2px;
        cursor: pointer;

        &:hover {
            color: #14a4e7;
        }
    }
}

.user-level {
    display: flex;
    align-items: center;

    img {
        width: .3rem;
        height: .3rem;
    }
}

.user-progress {
    margin-top: .1rem;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 2.13rem;
    overflow: hidden;
    border-radius: .08rem;
    background: #fff linear-gradient(#86BEF5 .5%, #fff 65%);


    .user-img {
        margin-bottom: .08rem;
        padding-top: .08rem;
        width: 100%;
        height: .94rem;
        cursor: pointer;

        .Uimg {
            position: relative;
            margin-top: -10px;
            width: 1.3rem;
            height: 1.3rem;

            .avatar-img {
                position: absolute;
                margin-top: 4px;
                margin-left: 2px;
                width: .82rem;
                height: .82rem;
                border-radius: 50%;
            }

            .avatar-box {
                position: absolute;
                width: 1.3rem;
                height: 1.3rem;
            }
        }
    }

    .user-name {
        font-size: .14rem;
        color: #666;
    }

    .user-level {
        cursor: pointer;

        i {
            color: #FF8800;
            vertical-align: -0.01rem;
        }

        .user-stars {
            color: #FF8800;
            font-size: .18rem;
            vertical-align: -0.01rem;
        }

        .l-username {
            padding: 0 .05rem;
            letter-spacing: .01rem;
            font-weight: 500;
        }
    }

    .user-progress {
        position: relative;
        width: 1.74rem;

        .u-info {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: .12rem;
            font-weight: bold;
            transform: translate(-50%, -50%) scale(.835);
        }

        .el-progress-bar {
            margin: 0 auto;
            border-radius: .1rem;
            box-shadow: 0 0 .05rem $glColor;
        }

        .el-progress-bar__inner {
            background: $glColor;
        }

        .el-progress-bar__outer {
            background: #fff;
        }
    }
}
</style>